import React from 'react';
import { Button, Drawer, notification } from 'antd';
import { axius, helpers, types } from 'helpers';
import { Close as CloseIcon } from '@mui/icons-material';
import { CuratorsAvatars, CuratorsSocials, LoadingMin, Status } from 'components';
import moment from 'moment';
import * as _ from 'lodash';


interface Props {
    item: types.Campaign | null;
    open: boolean;
    button?: boolean;

    onClose: () => void;
}
const CampaignsDetails = (props: Props) => {
    const { open, onClose } = props;

    const [item, setItem] = React.useState<types.Campaign | null>(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (open && props.item) {
            setLoading(true);
            axius.get(`campaigns/${props.item.id}`).then(res => {
                res.status === 200 && setItem(res.data);
                setLoading(false);
            });
        }
    }, [open, props.item]);

    const makePayment = () => {
        setLoading(true);
        if (item?.payment_link) {
            helpers.redirect(item.payment_link);
        } else {
            axius.post(`payments`, { campaign_id: item?.id }).then(res => {
                if (res.status === 201) {
                    helpers.redirect(res.data.checkout_url);
                } else {
                    notification.error({ ...res });
                    setLoading(false);
                }
            });
        }
    }

    const downloadResults = () => {
        const data: any[] = [];
        for (let curator in item?.results) {
            for (let platform in item?.results[curator]) {
                data.push({ Curator: curator, Platform: types.SocialPlatformsLabels[platform as types.SocialPlatforms], Link: item?.results[curator][platform] });
            }
        }
        helpers.downloadData(`${item?.name}`, data);
    }

    const close = () => {
        setItem(null);
        setLoading(true);
        onClose();
    }

    const dashed = `flex justify-between mt- text-gray-500 border-b border-dashed py-2`;

    return (
        <React.Fragment>
            <Drawer open={open} closable={false} placement="right" footer={null} width={480} onClose={close} prefixCls="CampaignsDetails">
                {loading && (
                    <div className="w-full h-full flex justify-center items-center">
                        <LoadingMin />
                    </div>
                )}

                {item && !loading && (
                    <>
                        <div className="flex justify-between items-centers mb-12">
                            <div className="text-2xl font-semibold">
                                Campaign Details
                                <Status status={item.status} />
                            </div>
                            <div className="bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transi h-[30px] w-[30px] flex justify-center items-center" onClick={close}>
                                <CloseIcon color="disabled" />
                            </div>
                        </div>

                        {item.status === types.CampaignStatus.REJECTED && (
                            <div className="mt-8s">
                                {[Object.keys(item.rejections)[0]].map(date => (
                                    <div className="px-4 py-4 bg-[#fc003f] text-white rounded-lg text-sm">
                                        <div className="">{item.rejections[date].reason}</div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div>
                            <div className="text-center my-6 overflow-x-scroll no-scrollbar px-4">
                                <CuratorsAvatars items={item.curators.map(c => c.logo_link)} />
                            </div>
                            <div className="border-b border-t px-4s py-6 hidden">
                                <div className="text-lg font-semibold mb-4 uppercase">Selected Platforms</div>
                                <CuratorsSocials items={_.uniq(item.curators.map(c => c.prices).flat().map(p => p.platform)).map(s => { return { count: 0, handle: '', platform: s } }) as types.Social[]} />
                            </div>
                            <div className="border-b border-t px-4s py-6">
                                <div className="text-lg font-semibold mb-4 uppercase">Selected Platforms</div>
                                {item.curators.map((curator, i) => (
                                    <div id={curator.id} className={(i + 1 === item.curators.length) ? dashed.replace('border-b', '') : dashed}>
                                        <div>{curator.name}</div>
                                        <div className="w-7/12 truncate text-right">{curator.prices.map(p => types.SocialPlatformsLabels[p.platform] || p.platform).join(', ')}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="border-b px-4s py-6">
                                <div className="text-lg font-semibold mb-4 uppercase">Details</div>
                                <div className="flex justify-between mt- text-gray-500 border-b border-dashed pb-2">
                                    <div>Name</div>
                                    <div className="w-7/12 truncate text-right">{item.name}</div>
                                </div>
                                {item.links.press && (
                                    <div className={dashed}>
                                        <div>Press release</div>
                                        <div className="w-7/12 truncate text-right">
                                            <a href={item.links.press} target="_blank">{item.links.press}</a>
                                        </div>
                                    </div>
                                )}
                                <div className={dashed}>
                                    <div>Campaign assets</div>
                                    <div className="w-7/12 truncate text-right">
                                        <a href={item.links.assets} target="_blank">{item.links.assets}</a>
                                    </div>
                                </div>
                                {item.release_date && (
                                    <div className={dashed}>
                                        <div>Date to publish article</div>
                                        <div>{moment(item.release_date).format('LL')}</div>
                                    </div>
                                )}
                                {item.publish_date && (
                                    <div className={dashed}>
                                        <div>Date to post on socials</div>
                                        <div>{moment(item.publish_date).format('LL')}</div>
                                    </div>
                                )}
                                {item.artist_name && (
                                    <div className={dashed}>
                                        <div>Artist name</div>
                                        <div>{item.artist_name}</div>
                                    </div>
                                )}
                                {item.caption && (
                                    <div className={dashed}>
                                        <div>Suggested caption</div>
                                        <div>{item.caption}</div>
                                    </div>
                                )}
                                <div className={dashed}>
                                    <div>Instagram handle</div>
                                    <div className="w-7/12 truncate text-right">@{item.links.instagram}</div>
                                </div>
                                <div className="flex justify-between mt- text-gray-500 pt-2">
                                    <div>Spotify profile</div>
                                    <div className="w-7/12 truncate text-right">
                                        <a href={` https://${item.links.profile}`} target="_blank"> https://{item.links.profile}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4s py-6">
                                <div className="text-lg font-semibold mb-4 uppercase">Cost Breakdown</div>
                                {item.curators.map(curator => (
                                    <div key={curator.id} className="flex justify-between mb-2 text-gray-500">
                                        <div>{curator.name}</div>
                                        <div>{helpers.format.currency(helpers.cost([{ id: curator.id, prices: curator.prices }]))}</div>
                                    </div>
                                ))}
                                <div className="flex justify-between mb-2 text-gray-500">
                                    <div>Submission fee</div>
                                    <div>{helpers.format.currency(item.fee)}</div>
                                </div>
                                <div className="flex justify-between items-center font-semibold mt-8">
                                    <div className="uppercase">Total</div>
                                    <div className="primary text-3xl">{helpers.format.currency(+item.price + +item.fee)}</div>
                                </div>
                            </div>
                            {item.status === types.CampaignStatus.APPROVED && (
                                <div className="text-center mt-8">
                                    <Button size="large" type="primary" block onClick={makePayment}>Make Payment</Button>
                                </div>
                            )}
                            {item.status === types.CampaignStatus.COMPLETED && (
                                <div className="text-center mt-8">
                                    <Button size="large" type="primary" block onClick={downloadResults}>Download Report</Button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Drawer>
        </React.Fragment>
    );
}

export default CampaignsDetails;