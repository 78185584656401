import React from 'react';
import { Avatar } from 'antd';
import { types } from 'helpers';
import { CuratorsSocials } from 'components';


import CuratorsDetails from './CuratorsDetails';

interface Props {
    item: types.Curator;
    className?: string;
}
const CuratorsCard = ({ item, className }: Props) => {

    const [details, setDetails] = React.useState(false);

    // const blogs = item.socials.filter(social => social.platform === 'blog');
    // const socials = item.socials.filter(social => social.platform !== 'blog');

    return (
        <React.Fragment>
            <div id="CuratorsCard" className={`border rounded-2xl px-3 pt-5 pb-3 shadow-mds pop ${className}`}>
                <Avatar src={item.logo_link} alt={item.name} className="h-[76px] w-[76px] cursor-pointer" onClick={() => setDetails(true)} />
                <div className="text-xl font-semibold truncate cursor-pointer mb-2 mt-1" onClick={() => setDetails(true)}>{item.name}</div>
                {/* {blogs.length > 0 && (
                    <>
                        <div className="text-sm font-semibold my-3">Blog Supported</div>
                        <CuratorsSocials items={blogs} />
                    </>
                )} */}
                <div className="truncate text-base text-gray-500 mb-3">{item.description}</div>
                {item.socials.length > 0 && (
                    <>
                        <div className="text-sm font-semibold my-3 hidden">Socials Supported</div>
                        <div className="flexs gap-2">
                            <CuratorsSocials
                                showKount="price"
                                items={item.socials.map(s => {
                                    const price = item.prices.find(p => p.platform === s.platform)?.price;
                                    return {
                                        ...s,
                                        price,
                                        handle: s.handle || '',
                                    };
                                })}
                            />
                        </div>
                    </>
                )}
            </div>

            <CuratorsDetails item={item} open={details} button={false} onClose={() => setDetails(false)} />
        </React.Fragment>
    );
};

export default CuratorsCard;